<template>
    <div
        class="rich-text--content list-icon rich-text"
        v-html="asHtml(data.content)"
    />
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    methods: {
        asHtml(content) {
            return this.$prismic.asHtml(content).replace('<img', '<img loading="lazy"').replace('<iframe', '<iframe loading="lazy" title="video"');
        }
    }
}
</script>
