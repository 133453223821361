<template>
    <section
        id="top-list"
        class="wrapper"
    >
        <div class="section">
            <div class="section-inner section-width-lg">
                <h2 class="section-header">{{ h2 }}</h2>
                <div 
                    v-if="$validateText(data.primary.teaser)"
                    class="rich-text section-teaser" 
                    v-html="$prismic.asHtml(data.primary.teaser)"
                />
                <slot v-if="data.primary.show_filter" name="filter" />
                <slot name="list" />
            </div>
        </div>
        <template v-if="structuredDataEnabled">
            <div
                v-if="data.primary.show_stats"
                class="section"
            >
                <div class="section-inner section-width-lg">
                    <div class="container">
                        <h2 class="section-inner__title">{{ $translate('bl_stats_h2', 'Statistik') }}</h2>
                        <div
                            class="section-inner__teaser"
                            v-html="statsText"
                        />
                    </div>

                    <div class="container table-container">
                        <table class="table-container__table">
                            <slot name="stats" />
                        </table>
                    </div>
                </div>
            </div>

            <div
                v-if="data.primary.show_interests"
                class="section"
            >
                <div class="section-inner section-width-lg">
                    <div class="container">
                        <h2 class="section-inner__title">{{ $translate('bl_interests_h2', 'Illustration av långivarnas räntor') }}</h2>
                        <div
                            class="section-inner__teaser"
                            v-html="interestText"
                        />
                    </div>

                    <div class="container table-container graph">
                        <table class="table-container__table">
                            <slot name="interests" />
                        </table>
                    </div>
                </div>
            </div>

            <div
                v-if="data.primary.show_toplist"
                class="section"
            >
                <div class="section-inner section-width-lg">
                    <div class="container">
                        <h2 class="section-inner__title">{{ toplistHeader }}</h2>
                        <div
                            class="section-inner__teaser"
                            v-html="toplistText"
                        />
                    </div>

                    <div class="container table-container table-container__table dropdown-body rich-text">
                        <slot name="toplist" />
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
        list: {
            type: Array,
            required: true,
        },
        statsText: {
            type: String,
            required: false,
            default: '',
        },
        interestText: {
            type: String,
            required: false,
            default: '',
        },
        toplistHeader: {
            type: String,
            required: false,
            default: ''
        },
        toplistText: {
            type: String,
            required: false,
            default: ''
        },
        structuredDataEnabled: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        h2() {
            return this.$prismic.asText(this.data.primary.h2)
                .replace(/%count%/g, Object.values(this.list).length);
        }
    },
    methods: {
        lender(id) {
            return this.$store.state.lenders[id];
        },
        model(lender) {
            return new this.$models.Loan(lender);
        }
    }
};
</script>

<style lang="scss" scoped>
.rich-text.section-teaser::v-deep {
    * {
        &:first-child {
            margin-top: 0;
        }
    }
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.wrapper {
    padding: 0;

    .graph {
        table {

            tr {
                th,
                td {
                    font-size: 12px;
                    padding: 15px;
                    text-align: left;

                    &:first-child {
                        @include border(right);
                        width: 150px;
                    }
                }
            }

            thead {
                tr {
                    @include border(bottom);

                    th:nth-child(2) {
                        @include flex(between);
                        padding: 15px;
                    }
                }
            }

            tbody {
                tr {
                    @include border(bottom);
                    &:last-child { border: 0; }

                    td {
                        &:last-child {
                            padding-left: 60px;
                            padding-right: 80px;

                            @include device(mobile) {
                                min-width: 150px;
                            }
                        }

                        a {
                            color: $green;
                            font-family: $fontHeader;
                            font-weight: bold;
                            text-decoration: none;
                            &:hover { text-decoration: underline; }
                        }

                        .bar {
                            position: relative;
                            transition: .15s ease-in;
                            width: 0;
                            background: $green;
                            height: 15px;
                            @include border-radius(2px);

                            .bar-text::v-deep {
                                position: absolute;
                                width: 45px;
                                height: 100%;
                                @include flex;

                                &:first-child { right: calc(100% + 10px); }
                                &:last-child { left: calc(100% + 10px); }

                                span { margin-left: 5px; }
                            }
                        }
                    }
                }
            }
        }
    }

    .section {
        width: 100%;

        &-inner {
            @include flex(center,start);

            &__title {
                color: $green;
                @include spacing(margin,6,bottom);
            }

            .container {
                width: 100%;
                &:first-child {
                    @include spacing(padding,12,bottom);

                    @include device(pad) {
                        flex: 1;
                        padding-bottom: 0;
                        @include spacing(padding,12,right);
                    }
                }
                &:last-child {
                    @include device(pad) {
                        flex: 2;
                    }
                }
            }
        }

        &:nth-child(1) {
            background: $cream;

            .section-inner {
                .lender-list {
                    width: 100%;
                    @include grid(1, 40px);
                }
                .show-more-button {
                    @include spacing(margin,6,top);
                }
            }
        }

        &:nth-child(2) {
            background: $lightgray;

            .section-inner {
                table {
                    tr {
                        @include border(bottom);
                        &:last-child { border: 0; }

                        th,
                        td {
                            @include font-size(12px,6px);
                            text-align: left;
                            &:first-child {
                                font-weight: bold;
                                @include border(right);
                            }
                        }
                    }
                }
            }
        }

        &:nth-child(3) {
            background: $lightgray;
            @include border(top);
        }

        &:nth-child(4) {
            background: $lightgray;
            @include border(top);
        }
    }


    ol::v-deep {
        display: table;
        @include spacing(padding,4);

        li {
            a {
                span {
                    color: $blue;
                    font-weight: bold;
                }

                color: $font;
                font-weight: normal;
                text-decoration: none;
                &:hover { text-decoration: underline; }
            }
        }
    }
}
</style>
