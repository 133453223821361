<template>
    <div class="table-container">
        <div class="table-container__image">
            <nuxt-link :to="$prismic.linkResolver(lender)">
                <PrismicImage :img="lender.data.image" w="120" h="auto" />
            </nuxt-link>

            <ButtonTarget :text="$translate('cta_to_application', 'Till ansökan')" background="green" :link="lender" size="md" placement="list" />
        </div>
        <table class="table-container__table">
            <thead>
                <tr>
                    <th>{{ $translate('lce_amortization_period', 'Amorteringstid') }}</th>
                    <th>{{ $translate('lce_monthly_payment', 'Månadsbetalning') }}</th>
                    <th>{{ $translate('lce_interest_cost', 'Räntekostnad') }}</th>
                    <th>{{ $translate('lce_fees', 'Avgifter') }}</th>
                    <th>{{ $translate('lce_payback', 'Återbetala') }}</th>
                    <th>{{ $translate('lce_after_interest_deduction', 'Efter ränteavdrag') }}</th>
                </tr>
            </thead>

            <tbody>
                <TableCalculationExampleTableRow v-for="(period, index) in [periodOne, periodTwo, periodThree]"
                    :key="index" :period="period" />
            </tbody>
        </table>

        <div class="table-container__example">
            {{
                $translate('lce_explanation', 'I exemplet har vi utgått från lägsta möjliga räntan hos {title} ({interest} %) vid ett lån på {amount}. Avikostnaden hos {title} är {adminFee} och uppläggningsavgiften är {setupFee}.')
                    .replace(/{amount}/g, $format.currency(amount))
                    .replace(/{title}/g, lender.data.title)
                    .replace('{interest}', exampleInterest)
                    .replace('{adminFee}', lenderModel.administrationFeeString)
                    .replace('{setupFee}', lenderModel.setupFeeString) 
            }}
        </div>
    </div>
</template>

<script>
import TableCalculationExampleTableRow from './TableCalculationExampleTableRow.vue';

export default {
    components: { TableCalculationExampleTableRow },
    props: {
        id: {
            type: String,
            required: true
        },
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            amount: this.slice.primary.table_calculation_amount || 5000,
        };
    },
    computed: {
        periodOne() {
            return this.calculate(this.slice.primary.calculations_period_1 || 12);
        },

        periodTwo() {
            return this.calculate(this.slice.primary.calculations_period_2 || 48);
        },

        periodThree() {
            return this.calculate(this.slice.primary.calculations_period_3 || 144);
        },

        lender() {
            return this.$store.state.lenders[this.id];
        },

        lenderModel() {
            return new this.$models.Loan(this.lender, this.$store);
        },

        exampleInterest() {
            if (this.lenderModel.interestType === 'fixed') {
                return this.lender.data.core_api.brand_data.interest_fixed;
            }
            return this.lender.data.core_api.brand_data.interest_interval_min;
        }
    },
    methods: {
        calculate(months) {


            const fees =
                this.lenderModel.administrationFee * (months) +
                this.lenderModel.setupFee;

            const interest = this.$calculateInterestCost(
                this.amount, this.exampleInterest / 100, months);

            let payback = this.amount + interest + fees;
            let paybackAndInterestBack = this.amount + interest * 0.7 + fees;
            let monthlyPayment = payback / months;

            return {
                amortization: months,
                fees: this.$format.currency(fees, 2),
                interest: this.$format.currency(interest, 2),
                payback: this.$format.currency(payback, 2),
                paybackAndInterestBack: this.$format.currency(paybackAndInterestBack, 2),
                monthlyPayment: this.$format.currency(monthlyPayment, 2)
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.table-container {

    &__image {
        @include flex(between);
        @include spacing(padding, 4);
        width: 100%;
    }

    &__table {
        width: 100%;
        @include border((bottom, top));
        @include spacing(padding, 6, (top, bottom));
        background: $white;
    }

    &__example {
        width: 100%;
        @include spacing(padding, 4);
        @include font-size(12px, 8px);
        text-align: center;

        a {
            font-weight: bold;
            text-decoration: none;
            color: $blue;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
