<template>
    <tr>
        <td>
            {{ formatMonths(period.amortization) }}
        </td>
        <td>{{ period.monthlyPayment }}</td>
        <td>{{ period.interest }}</td>
        <td>{{ period.fees }}</td>
        <td>{{ period.payback }}</td>
        <td>{{ period.paybackAndInterestBack }}</td>
    </tr>
</template>

<script>
export default {
    props: {
        period: {
            type: Object,
            required: true
        }
    },
    
    methods: {
        formatMonths(months) {
            if (months >= 12) {
                return `${Number((months / 12).toFixed(1))} ${this.$translate('year', 'år')}`;
            }
            return `${months} ${this.$translate('month_short', 'mån')}`;
        }
    },
};
</script>
