<template>
    <ListTemplate
        :data="data"
        :list="list"
        stats-text="TBD"
        interest-text="TBD"
    >
        <template #list>
            <div class="switch-container">
                <span 
                    :class="{'active': !showAverageInterest}" 
                    @click="showAverageInterest = false"
                >
                    {{ $translate('lm_list_interest_header', 'Listränta') }}
                </span>
                <span 
                    :class="{'active': showAverageInterest}" 
                    @click="showAverageInterest = true"
                >
                    {{ $translate('lm_average_interest_header', 'Snittränta') }}
                </span>
            </div>
            <ul class="lender-list">
                <LenderMortgage
                    v-for="lender in list"
                    :id="lender.id"
                    :key="lender.id"
                    :show-average-interest="showAverageInterest"
                />
            </ul>
        </template>

        <template #stats></template>

        <template #interests></template>
    </ListTemplate>
</template>
<script>
import ListTemplate from './ListTemplate.vue';
import map from 'lodash/map';
import LenderMortgage from '@/components/slices/lists/list-items/ListItemMortgage.vue';
export default {
    components: { 
        ListTemplate, 
        LenderMortgage, 
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            showAverageInterest: false
        };
    },
    computed: {
        list() {
            if (this.data.primary.predefined_list.id) {
                let list = this.$store.state.lists[this.data.primary.predefined_list.id];
                return map(list.data.lenders, 'lender');
            }
            return map(this.data.items, 'lender');
        }
    }
};
</script>

<style lang="scss" scoped>
#top-list::v-deep .section-inner {
    @include flex(start,start);
}
.wrapper {
    padding: 0;

    .section {
        width: 100%;

        &-inner {
            &__title {
                color: $green;
                @include spacing(margin,6,bottom);
            }

            .container {
                &:first-child {
                    @include spacing(padding,12,bottom);

                    @include device(pad) {
                        flex: 1;
                        padding-bottom: 0;
                        @include spacing(padding,12,right);
                    }
                }
                &:last-child {
                    @include device(pad) {
                        flex: 2;
                    }
                }
            }
        }
        .switch-container {
            border-radius: 3px;
            display: flex;
            gap: 0.5rem;
            padding: 7px;
            background-color: $lightgray;
            width: fit-content;
            @include spacing(margin, 2, bottom);
            span {
                border-radius: 3px;
                padding: 4px 8px;
                cursor: pointer;
                &:hover {
                    box-shadow: $bxs;
                }
            }
            .active {
                box-shadow: $bxs;
                background: $white;
            }
        }

        &:nth-child(1) {
            background: $cream;

            .section-inner {
                .lender-list {
                    width: 100%;
                    @include grid(1, 40px);
                }
            }
        }

        &:nth-child(2) {
            background: $lightgray;

            .section-inner {
                table {
                    tr {
                        @include border(bottom);
                        &:last-child { border: 0; }

                        th,
                        td {
                            @include font-size(12px,6px);
                            text-align: left;
                            &:first-child {
                                font-weight: bold;
                                @include border(right);
                            }
                        }
                    }
                }
            }
        }

        &:nth-child(3) {
            background: $lightgray;
            @include border(top);
        }
    }
}
</style>
