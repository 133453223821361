<template>
    <Section width="lg">
        <div class="container">
            <PrismicImage
                :img="data.primary.image"
                :lazy="true"
                w="212"
                h="340"
            />
        </div>

        <div class="container">
            <div class="container-inner">
                <h2 class="section-header">{{ $prismic.asText(data.primary.h2) }}</h2>

                <div
                    class="rich-text"
                    v-html="$prismic.asHtml(data.primary.teaser)"
                />

                <nuxt-link
                    :to="$prismic.linkResolver(data.primary.link_relation)"
                    class="btn btn-green btn-md"
                >
                    {{ data.primary.cta_link_text }}
                </nuxt-link>
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    .section-inner {
        @include grid(1, 40px);

        @include device(pad) {
            @include grid(2, 80px);
        }

        .container {
            h2 {

            }

            &:first-child {
                @include flex;
            }

            &:last-child {
                @include flex;

                a {
                    @include spacing(margin, 8, top);
                    color: $white;
                    font-weight: normal;
                }

                .container-inner {
                    @include flex(start,start);
                }
            }

            .rich-text {
                width: 100%;
                p:first-child { margin-top: 0; }
                p:last-child { margin-bottom: 0; }
            }
        }
    }
}
</style>
