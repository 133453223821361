<template>
    <li>
        <!-- TODO Maybe use a component somewhere -->
        <a :href="$helpers.getRedirectUrl(lender, 'list-text-toplist', position)" target="_blank" rel="nofollow">
            <span>{{ lender.data.title }}</span>: {{ text }}
        </a>
    </li>
</template>
<script>
export default {
    props: {
        lenderId: {
            type: String,
            required: true
        },
        fixedAmount: {
            type: Number,
            required: false,
            default: 0,
        },
        position: {
            type: Number, 
            required: true,
        }
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.lenderId];
        },
        model() {
            return new this.$models.Loan(this.lender);
        },
        text() {
            // Todo: loan fix lurig
            let text = this.$translate('random', 'Låna ');
            if (this.fixedAmount && this.fixedAmount >= this.model.minAmount && this.fixedAmount <= this.model.maxAmount) {
                text += this.$format.currency(this.fixedAmount);
            }
            else {
                text += this.model.amountString;
            }

            if (! this.model.takesUC && this.model.acceptsPaymentRemarks) {
                text += ' ' + this.$translate('random', 'utan UC och trots anmärkning');
            }
            else {
                if (! this.model.takesUC) {
                    text += ' ' + this.$translate('random', 'utan UC');
                }

                if (this.model.acceptsPaymentRemarks) {
                    text += ' ' + this.$translate('random', 'trots anmärkning');
                }
            }
            text += ' ' + this.$translate('random', 'med ränta {interestString}')
                .replace('{interestString}', this.model.interestString);

            return text;
        }
    }
};
</script>