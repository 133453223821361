<template>
    <Section
        class="banner"
        width="sm"
    >
        <h1>{{ document.data.title }}</h1>
        <p v-if="hasAuthor">
            <nuxt-link :to="$prismic.linkResolver(author)"> {{ author.data.name }}</nuxt-link> - 
            <span>{{ publication }}</span>
        </p>
        
        <PrismicImage
            :img="defaultPostImage"
            :alt="document.data.title"
            w="700"
            h="350"
            :lazy="false"
        />
        <div
            class="rich-text"
            v-html="$prismic.asHtml(document.data.preamble)"
        />
    </Section>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        publication() {
            return this.document.last_publication_date.split('T')[0];
        },

        author() {
            return this.$store.state.authors[this.document.data.author.id];
        },

        hasAuthor() {
            return this.document.data.author;
        },

        defaultPostImage() {
            if (this.document.data.image.url) return this.document.data.image;
            else {
                let image = {
                    dimensions: {
                        width: 1920,
                        height: 960
                    },
                    alt: this.document.data.image.title,
                    copyright: null,
                    url: 'https://images.prismic.io/lanen/a534bdf7-7229-4192-8883-4f32f2cb3866_post-default-img.webp?auto=compress,format'
                }
                return image
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    section::v-deep {
        padding-bottom: 0;

        .section-inner {
            text-align: center;
            @include grid(1, 20px);

            h1 { 
                color: $green;
             }

            img {
                @include border-radius(3px);
                max-width: 100%;
                height: auto;
            }

            p {
                a {
                    color: $green;
                    font-family: $fontHeader;
                    text-decoration: none;
                    // @include font-size(20px);
                    &:hover { text-decoration: underline; }
                }
            }
        }
    }
</style>
