<template>
    <nuxt-link :to="$prismic.linkResolver(article)">
        <PrismicImage
            :img="image"
            :alt="article.data.title"
            w="350"
        />
        <div class="container-inner">
            <span class="container-inner__title">{{ article.data.title }}</span>

            <div
                v-if="author"
                class="container-inner__author"
            >   
                <span>{{ publicationDate }}</span>
                {{ $translate('by', 'av') }}
                <span>{{ author.data.name }}</span>
            </div>
        </div>
    </nuxt-link>
</template>

<script>
export default {
    props: {
        article: {
            type: Object,
            required: true
        }
    },
    computed: {
        author() {
            if (this.article.data.author) return this.$store.state.authors[ this.article.data.author.id ];
            return false;
        },

        publicationDate() {
            return this.$format.date(this.article.first_publication_date);
        },

        image() {
            if (this.article.data.image.url) {
                return this.article.data.image;
            }

            return {
                dimensions: {
                    width: 1920,
                    height: 960
                },
                alt: this.article.data.image.title,
                copyright: null,
                url: 'https://images.prismic.io/lanen/a534bdf7-7229-4192-8883-4f32f2cb3866_post-default-img.webp?auto=compress,format'
            };
        }
    }
};
</script>

<style lang="scss" scoped>

    a {
        @include flex(between, stretch);
        @include border-radius(5px);
        background: $white;
        box-shadow: $bxs;
        text-decoration: none;
        color: $font;
        overflow: hidden;
        margin-bottom: 20px;
        transition: .1s ease-in-out;
        border: 1px solid #e6e6e6;

        &:hover { box-shadow: 0 4px 10px -2px rgba(0,0,0,.3); }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;

            @include device(pad) {
                flex: 1;
                max-width: 150px;
            }
        }

        .container-inner {
            flex: 1;
            text-align: left;
            @include spacing(padding,4);
            @include grid(1, 20px);

            &__title {
                font-family: $fontHeader;
                @include font-size(16px);
            }

            &__author {
                color: $green;
                @include font-size(12px);

                span { font-weight: bold; }
            }
        }
    }

</style>
