var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListTemplate',{attrs:{"data":_vm.data,"list":_vm.list,"stats-text":_vm.statsText,"toplist-header":_vm.toplistHeader,"toplist-text":_vm.toplistText,"structured-data-enabled":Boolean(_vm.filteredList.length)},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('filter-form',{attrs:{"filters":_vm.filters,"list-length":_vm.filteredList.length,"unfiltered-list-length":_vm.listToFilter.length,"active-filters-length":_vm.activeFilters.length},on:{"updateCheckboxValue":_vm.updateCheckboxValue,"updateSliderValue":_vm.updateSliderValue}})]},proxy:true},{key:"list",fn:function(){return [_c('ul',{staticClass:"lender-list"},_vm._l((_vm.filteredListCapped),function(lender){return _c('LenderCorporate',{key:lender.id,attrs:{"id":lender.id}})}),1),_vm._v(" "),(!_vm.showAll && !_vm.useOriginalList && _vm.filteredList.length > 10)?_c('button',{staticClass:"btn btn-md btn-green show-more-button",on:{"click":function($event){_vm.showAll = true}}},[_vm._v("\n            "+_vm._s(_vm.$translate('filter_list_show_all', 'Visa alla'))+"\n        ")]):_vm._e()]},proxy:true},{key:"stats",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_stats_min_amount', 'Lägsta lånebelopp')))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.$format.currency(_vm.stats.minAmount, 2)))])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_stats_max_amount', 'Högsta lånebelopp')))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.$format.currency(_vm.stats.maxAmount, 2)))])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_demands_personal_bail', 'Kräver personlig borgen')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_demands_personal_bail_calculation', '{amountOfLenders} långivare {procent} kräver personlig borgen')
                            .replace('{amountOfLenders}', _vm.stats.countRequiresPersonalBail)
                            .replace('{procent}', _vm.$format.percentage(100 * _vm.stats.requiresPersonalBail, 2)))+"\n                ")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_demands_security', 'Kräver säkerhet')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_demands_security_calculation', '{amountOfLenders} långivare ({procent}) kräver säkerhet')
                            .replace('{amountOfLenders}', _vm.stats.countRequiresCollateral)
                            .replace('{procent}', _vm.$format.percentage(100 * _vm.stats.requiresCollateral, 2)))+" \n                ")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_payment_remarks_company', 'Betalningsanmärkningar på företaget')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_payment_remarks_on_the_company_calculation', '{amountOfLenders} långivare ({procent}) godkänner betalningsanmärkningar på företaget')
                            .replace('{amountOfLenders}', _vm.stats.countAcceptsPaymentRemarksCompany)
                            .replace('{procent}', _vm.$format.percentage(100 * _vm.stats.acceptsPaymentRemarksCompany, 2)))+" \n                ")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_payment_remarks_on_the_company_signer', 'Betalningsanmärkningar på firmatecknaren')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_payment_remarks_on_the_company_signer_calculation', '{amountOfLenders} långivare ({procent}) godkänner betalningsanmärkningar på firmatecknaren')
                            .replace('{amountOfLenders}', _vm.stats.countAcceptsPaymentRemarksRepresentative)
                            .replace('{procent}', _vm.$format.percentage(100 * _vm.stats.acceptsPaymentRemarksRepresentative, 2)))+" \n                ")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_takes_uc_on_company', 'Tar uc på företaget')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_takes_uc_on_company_calculation', '{amountOfLenders} långivare ({procent}) gör en uc-kreditupplysning på företaget')
                            .replace('{amountOfLenders}', _vm.stats.countTakesUCCompany)
                            .replace('{procent}', _vm.$format.percentage(100 * _vm.stats.takesUCCompany, 2)))+"\n                ")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_takes_uc_on_company_signer', 'Tar uc på firmatecknaren')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_takes_uc_on_company_signer_calculation', '{amountOfLenders} långivare ({procent}) gör en uc-kreditupplysning på firmatecknaren')
                            .replace('{amountOfLenders}', _vm.stats.countTakesUCRepresentative)
                            .replace('{procent}', _vm.$format.percentage(100 * _vm.stats.takesUCRepresentative, 2)))+" \n                ")])])])]},proxy:true},{key:"toplist",fn:function(){return [_c('ol',_vm._l((_vm.filteredListCapped.slice(0, 10)),function(lender,index){return _c('toplist-corporate-item',{key:("toplist" + (lender.id)),attrs:{"lender-id":lender.id,"position":index + 1}})}),1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }