<template>
    <Section width="lg" class="section-compare-table">
        <div v-if="data.primary.h2 || data.primary.p" class="compare-lenders-padding">
            <h2 v-if="data.primary.h2" class="compare-lenders-title">{{ data.primary.h2 }}</h2>
            <div 
                v-if="$validateText(data.primary.teaser)"
                class="rich-text" 
                v-html="$prismic.asHtml(data.primary.teaser)"
            />
        </div>
        <div class="compare-table-scroll container table-container">
            <table class="compare-table">
                <tbody>
                    <tr>
                        <th />
                        <th v-for="(lender, index) in lenders" :key="index" class="company-logo">
                            <nuxt-link :to="$prismic.linkResolver(lender)">
                                <prismic-image w="100" :img="lender.data.image" />
                            </nuxt-link>
                        </th>
                    </tr>
                    <tr>
                        <th class="fw-bold">{{ $translate('amount', 'Belopp') }}</th>
                        <td v-for="(model, index) in models" :key="`amount-${index}`">{{ model.amountString }}</td>
                    </tr>
                    <tr>
                        <th class="fw-bold">{{ $translate('duration', 'Löptid') }}</th>
                        <td v-for="(model, index) in models" :key="`duration-${index}`">{{ model.getDurationString() }}</td>
                    </tr>
                    <tr>
                        <th class="fw-bold">{{ $translate('effective_interest', 'Effektiv ränta') }}</th>
                        <td v-for="(model, index) in models" :key="`eff-interest-${index}`">{{ model.effectiveInterestString }}</td>
                    </tr>
                    <template v-if="data.primary.table_type === 'small_loan'">
                        <tr>
                            <th class="fw-bold">{{ $translate('direct_payout', 'Direktutbetalning') }}</th>
                            <td v-for="(model, index) in models" :key="`direct_payout-${index}`">{{ model.hasDirectPayments ? $translate('yes', 'Ja') : $translate('no', 'Nej') }}</td>
                        </tr>
                        <tr>
                            <th class="fw-bold">{{ $translate('without_uc', 'Utan UC') }}</th>
                            <td v-for="(model, index) in models" :key="`without_uc-${index}`">{{ model.takesUC ? $translate('no', 'Nej') : $translate('yes', 'Ja') }}</td>
                        </tr>
                        <tr>
                            <th class="fw-bold">{{ $translate('payment_remarks', 'Betalningsanmärkning') }}</th>
                            <td v-for="(model, index) in models" :key="`payment_remarks-${index}`">{{ model.acceptsPaymentRemarks ? $translate('yes', 'Ja') : $translate('no', 'Nej') }}</td>
                        </tr>
                    </template>
                    <template v-else-if="data.primary.table_type === 'large_loan'">
                        <tr>
                            <th class="fw-bold">{{ $translate('setup_fee', 'Uppläggningsavgift') }}</th>
                            <td v-for="(model, index) in models" :key="`setup_fee-${index}`">{{ model.setupFeeString }}</td>
                        </tr>
                    </template>
                    <template v-else-if="data.primary.table_type === 'broker'">
                        <tr>
                            <th class="fw-bold">{{ $translate('connected_banks', 'Anslutna banker') }}</th>
                            <td v-for="(model, index) in models" :key="`connected_banks-${index}`">{{ model.connectedBanks }}</td>
                        </tr>
                    </template>
                    <tr>
                        <th class="fw-bold">{{ $translate('to_application', 'Till ansökan') }}</th>
                        <td v-for="(lender, index) in lenders" :key="index">
                            <ButtonTarget
                                v-if="lender.data.redirect_enabled"
                                class="to-application"
                                :link="lender"
                                :text="$translate('to_application', 'Till ansökan')"
                                background="green"
                                size="md"
                                placement="comparison-table"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        lenders() {
            return this.data.items
                .map(iterator => iterator.lender)
                .filter(lender => lender.id)
                .map(lender => this.$store.state.lenders[lender.id]);
        },
        models() {
            return this.lenders.map(lender => new this.$models.Loan(lender));
        }
    },
};
</script>
<style>
    .to-application.btn-md {
        padding: 12px 16px !important;
    }
    tbody tr:nth-child(even){
        background-color: #f8f8f8;
    }
    .compare-lenders-title {
        color: #5b8b36;
        margin: 0 0 1rem 0;
    }
    .section-compare-table {
        background-color: #f8f8f8;
    }
    .compare-lenders-padding {
        padding: 0 0 2rem 0;
    }
    .compare-table-scroll {
        overflow: auto;
    }
    table.compare-table {
        width: 100%;
        text-align: left;
        overflow: hidden;
    }
    .compare-table td {
        white-space: nowrap;
        padding: 1.5rem 1.5rem 1.5rem 0;
    }
    .compare-table tr {
        border-bottom: 2px solid rgba(0, 0, 0, 0.03);
    }
    .company-logo {
        vertical-align: middle !important;
        padding: 1.5rem 0 0.25rem 0!important;
    }
    .fw-bold {
        white-space: nowrap;
        font-weight: bold;
        padding: 1.5rem 1rem 0.25rem 1rem !important;
    }
</style>