<template>
    <Section width="sm" class="bank-cooperations">
        <div 
            v-if="$validateText(data.primary.header)"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.header)" 
        />
        <div class="bank-cooperations-wrapper">
            <div 
                v-for="(item, index) in items" 
                :key="index"
                class="image-container"
            >
                <nuxt-link 
                    v-if="item.link.id"
                    :to="$prismic.linkResolver(item.link)"
                >
                    <prismic-image
                        :img="item.image"
                        w="150"
                        h="55"
                    />
                </nuxt-link>
                <prismic-image
                    v-else
                    :img="item.image"
                    w="150"
                    h="55"
                />
            </div>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        items() {
            return this.data.items
                .filter(item => item.image.url);
        }
    }
};
</script>
<style lang='scss' scoped>
    .bank-cooperations {
        .bank-cooperations-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 0.5rem;

            .image-container {
                img {
                    max-width: 85%;
                    object-fit: contain;
                }
            }
            
            @include device(pad) {
                grid-template-columns: repeat(3, 1fr);
                row-gap: 2rem;
                
                img {
                    max-width: 100%;
                }
            }
        }
    }
</style>